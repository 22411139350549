import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        criteriaId: String,
        title: String,
        products: Array,
    };

    initialize() {
        this.eventListeners = new Map();
        console.log('[ProductTracking] Controller initialized');
    }

    connect() {
        console.log('[ProductTracking] Controller connected', {
            criteriaId: this.criteriaIdValue,
            title: this.titleValue,
            productsCount: this.productsValue?.length || 0,
        });

        // Set up click handlers for all tour cards
        this.setupProductTracking();
    }

    disconnect() {
        console.log(
            '[ProductTracking] Controller disconnected - cleaning up listeners',
        );
        this.removeEventListeners();
    }

    setupProductTracking() {
        const criteriaId = this.criteriaIdValue;
        const products = this.productsValue;
        const title = this.titleValue;

        console.log(
            '[ProductTracking] Setting up tracking for products:',
            products,
        );

        // For each product, set up click tracking
        products.forEach((product) => {
            const tourCode = product.productId;
            const elementId = `reco-${criteriaId}-tour-card-${tourCode}`;
            const element = document.getElementById(elementId);

            if (element) {
                console.log(
                    `[ProductTracking] Found element for tour ${tourCode}:`,
                    element,
                );

                const clickHandler = () => {
                    console.log(
                        `[ProductTracking] Tour ${tourCode} clicked, pushing to dataLayer:`,
                        {
                            event: 'santianoEcommerce',
                            eventCommand: 'productClick',
                            productList: [product],
                        },
                    );

                    if (window.dataLayer) {
                        window.dataLayer.push({
                            event: 'santianoEcommerce',
                            eventCommand: 'productClick',
                            currencyCode: 'EUR',
                            productImpressionListName: `ListHP - ${title}`,
                            productList: [product],
                        });
                    } else {
                        console.warn('[ProductTracking] dataLayer not found!');
                    }
                };

                element.addEventListener('click', clickHandler);
                this.eventListeners.set(element, clickHandler);
            } else {
                console.warn(
                    `[ProductTracking] Could not find element with ID: ${elementId}`,
                );
            }
        });

        console.log(
            `[ProductTracking] Setup complete - attached ${this.eventListeners.size} listeners`,
        );
    }

    removeEventListeners() {
        this.eventListeners.forEach((handler, element) => {
            if (element) {
                element.removeEventListener('click', handler);
            }
        });

        console.log(
            `[ProductTracking] Removed ${this.eventListeners.size} event listeners`,
        );

        this.eventListeners.clear();
    }
}
