import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    toggle(e) {
        e.currentTarget
            .querySelector('.dropdown-content')
            ?.classList.toggle('hidden');

        const upIcon = e.currentTarget.querySelector('.up');
        const downIcon = e.currentTarget.querySelector('.down');

        if (upIcon && downIcon && upIcon.classList.contains('hidden')) {
            upIcon.classList.remove('hidden');
            downIcon.classList.add('hidden');
        } else {
            upIcon.classList.add('hidden');
            downIcon.classList.remove('hidden');
        }
    }
}
