import { Controller } from '@hotwired/stimulus';
import Splide from '@splidejs/splide';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    splide;

    connect() {
        this.splide = new Splide('#banner-carousel', {
            type: 'loop',
            arrows: false,
            pagination: false,
            perPage: 1,
            autoplay: true,
            drag: false,
            lazyLoad: 'nearby',
            preloadPages: 1,
        }).mount();
    }
}
