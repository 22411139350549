import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class AccordionController extends Controller {
    static targets = ['content'];

    toggle(event) {
        // get closest parent that has data-action attribute, if we click on the +/- icon
        const parent = event.target.closest('[data-action]');
        const divContent = parent.nextElementSibling;
        if (divContent.getAttribute('aria-expanded') === 'true') {
            divContent.style.height = '0px';
            delete divContent.setAttribute('aria-expanded', 'false');
        } else {
            divContent.style.height = divContent.scrollHeight + 'px';
            divContent.setAttribute('aria-expanded', 'true');
        }

        // toggle show/hide + and - icons
        divContent.previousElementSibling
            .querySelector('span[data-expanded]')
            .classList.toggle('hidden');
        divContent.previousElementSibling
            .querySelector('span:not([data-expanded])')
            .classList.toggle('hidden');
    }
}
