import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['select'];

    toggle(event) {
        const selectMultiples = document.querySelectorAll('.select-multiple');
        selectMultiples.forEach((selectMultiple) => {
            if (selectMultiple !== this.selectTarget) {
                selectMultiple.classList.remove('select-multiple__display');
            }
        });

        this.selectTarget.classList.toggle('select-multiple__display');
        window.addEventListener('click', this.hide);
    }

    hide = (event) => {
        if (event && this.selectTarget.contains(event.target)) {
            return;
        }

        this.selectTarget.classList.remove('select-multiple__display');
        window.removeEventListener('click', this.hide);
    };
}
