import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash/debounce';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = [
        'stepsTabs',
        'stepsContents',
        'selectedDateFidelityPoints',
        'fidelityPointsAbove9000Wrapper',
        'fidelityPointsWrapper',
        'fidelityPointsAmount',

        'navContainer',
        'navElement',

        'bookButton',
        'form',
        'asideContainer',
        'nextDatesSelect',
        'travelTourDatesContainer',

        'nextDatesError',
        'childrenAgeError',
        'childrenNumberError',

        'travellersDescription',
        'travellersStatus',
        'travellersTooltip',

        'actualPrice',
        'minPrice',
        'rebatePercentage',
        'fromLabel',
    ];
    connect() {
        this.init();
    }

    /**
     * Select the day to display and hide to others
     * @param event
     */
    handleSelectStep(event) {
        const stepIndex = event.params?.index;

        // hit pagination limit, do nothing
        if (stepIndex < 0 || stepIndex === this.stepsTabsTargets.length) {
            return;
        }
        this.stepsTabsTargets.forEach((tab) => {
            tab.classList.remove(
                'border-b-4',
                'border-blue-brand',
                'border-solid',
                'text-content-brand',
                'font-semibold',
            );
        });
        this.stepsTabsTargets[stepIndex].classList.add(
            'border-b-4',
            'border-blue-brand',
            'border-solid',
            'text-content-brand',
            'font-semibold',
        );
        this.stepsContentsTargets.forEach((s) => {
            s.classList.add('hidden');
        });
        this.stepsContentsTargets[stepIndex].classList.remove('hidden');
        this.dispatchContentShownEvent(this.stepsContentsTargets[stepIndex]);
    }

    dispatchContentShownEvent(element) {
        // Dispatch a custom event that other controllers can listen for
        const event = new CustomEvent('content-shown', {
            bubbles: true,
            detail: { container: element },
        });
        element.dispatchEvent(event);
    }

    init() {
        // for closed tour
        if (this.hasBookButtonTarget) {
            this.bookButtonTarget?.addEventListener('click', (event) => {
                var error = false;
                if (this.hasNextDatesErrorTarget)
                    this.nextDatesErrorTarget.classList.add('hidden');
                if (this.hasChildrenAgeErrorTarget)
                    this.childrenAgeErrorTarget.classList.add('hidden');
                if (this.hasChildrenNumberErrorTarget)
                    this.childrenNumberErrorTarget.classList.add('hidden');

                // loading spinner
                const buttonSpinner =
                    this.bookButtonTarget.querySelector('.spinner');
                const buttonSpanText =
                    this.bookButtonTarget.querySelector('span');
                if (buttonSpinner) {
                    buttonSpinner.classList.add('inline-block');
                    buttonSpinner.classList.remove('hidden');
                }
                if (buttonSpanText) buttonSpanText.classList.add('hidden');
                var isDesktop = window.innerWidth >= 1300;

                if (this.nextDatesSelectTarget.value === '') {
                    error = true;

                    if (isDesktop) {
                        this.nextDatesErrorTarget.classList.add('flex');
                        this.nextDatesErrorTarget.classList.remove('hidden');
                    }

                    if (this.hasTravelTourDatesContainerTarget) {
                        window.scrollTo({
                            top: this.getOffsetTop(
                                this.travelTourDatesContainerTarget,
                            ),
                            behavior: 'smooth',
                        });
                    }
                }

                if (document.getElementById('nbChildren')?.value === '') {
                    error = true;
                    if (isDesktop && this.hasChildrenNumberErrorTarget) {
                        this.childrenNumberErrorTarget.classList.add('flex');
                        this.childrenNumberErrorTarget.classList.remove(
                            'hidden',
                        );
                    }
                }

                Array.from(
                    document.querySelectorAll('select[name="childrenAge[]"]'),
                ).map((select) => {
                    if (select.value == null || select.value === '') {
                        if (isDesktop) {
                            this.childrenAgeErrorTarget.classList.add('flex');
                            this.childrenAgeErrorTarget.classList.remove(
                                'hidden',
                            );
                        }
                        error = true;
                    }
                });

                if (error) {
                    // remove loading spinner
                    if (buttonSpinner) {
                        buttonSpinner.classList.remove('inline-block');
                        buttonSpinner.classList.add('hidden');
                    }
                    if (buttonSpanText)
                        buttonSpanText.classList.remove('hidden');
                    event.preventDefault();
                    return false;
                }
            });
        }
        this.formTarget.addEventListener('submit', () => {
            if (this.hasBookButtonTarget) this.bookButtonTarget.disabled = true;
        });

        // wait for svelte component to load
        // TODO make <aside> block in Svelte
        const observer = new MutationObserver(() => {
            const inputs = document.querySelectorAll(
                'input[name="startDateTable"]',
            );
            if (inputs.length > 0) {
                inputs.forEach((input) => {
                    input.addEventListener('click', function (event) {
                        event.stopPropagation();
                    });

                    input.addEventListener('change', (event) => {
                        event.stopPropagation();

                        var selectedDate = event.target.value;
                        document.querySelector(
                            'select[name="startDate"]',
                        ).value = selectedDate;
                        this.updateAside(selectedDate);
                    });
                });
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });

        /**
         * Handle synchronization between select dropdown and radio buttons for dates.
         * When a date is selected in the dropdown, update the aside panel and synchronize
         * the corresponding radio button in the date table.
         * @listens {Event} change - Listens for changes on the startDate select element
         **/
        const startDateSelect = document.querySelector(
            `select[name="startDate"]`,
        );
        startDateSelect?.addEventListener('change', (event) => {
            var selectedDate = event.target.value;

            this.updateAside(selectedDate);
            const previouslyCheckedInput = document.querySelector(
                `input[name="startDateTable"]:checked`,
            );
            if (previouslyCheckedInput) previouslyCheckedInput.checked = false;

            if (selectedDate) {
                document.querySelector(
                    `input[name="startDateTable"][value="${selectedDate}"]`,
                ).checked = true;
            }
        });

        /// scroll debounce
        window.addEventListener(
            'scroll',
            debounce(this.activateCurrentNavigationLink.bind(this), 100),
        );
        this.activateCurrentNavigationLink();

        // smooth scroll

        var headerLink = document.getElementById('header-link');

        this.navElementTargets?.forEach((navElement) =>
            navElement.addEventListener('click', (event) =>
                this.smoothScroll(event),
            ),
        );
        if (headerLink) {
            headerLink.addEventListener('click', (event) =>
                this.smoothScroll(event),
            );
        }
    }

    /**
     * This helper function:
     * Traverses up the DOM tree through offset parents
     * Accumulates all offsetTop values
     * Provides the same value as jQuery's offset().top
     * @param element
     * @returns {number}
     */
    getOffsetTop(element) {
        let offsetTop = 0;
        while (element) {
            offsetTop += element.offsetTop;
            element = element.offsetParent;
        }
        return offsetTop;
    }

    smoothScroll(event) {
        const target = document.querySelector(
            event.currentTarget.getAttribute('href'),
        );
        const headerOffset = this.navContainerTarget.clientHeight || 0;
        event.preventDefault();

        // Usage in smoothScroll:
        const thresh = Math.floor(
            this.getOffsetTop(target) - headerOffset - 10,
        );

        window.scrollTo({
            top: thresh,
            behavior: 'smooth',
        });
    }

    activateCurrentNavigationLink() {
        let latestActiveLink;
        this.navElementTargets?.forEach((linkElement) => {
            const target = document.getElementById(
                linkElement.getAttribute('href').replace('#', ''),
            );
            const scrollThreshold = Math.floor(
                this.getOffsetTop(target) -
                    this.navContainerTarget.clientHeight -
                    10,
            );
            if (target && window.scrollY >= scrollThreshold) {
                latestActiveLink = linkElement;
                //  mobile scroll current active link into viewport
                // if ($link[0]) $link[0].scrollIntoView();
            }
            linkElement.classList.remove('after:w-full', 'text-content-brand');
        });
        if (latestActiveLink) {
            latestActiveLink.classList.add(
                'after:w-full',
                'text-content-brand',
            );
        }
    }

    updateAside(selectedDate) {
        if (!selectedDate) {
            if (this.hasMinPriceTarget)
                this.minPriceTarget.classList.remove(
                    'is-hidden minprice--small',
                );
            if (this.hasTravellersDescriptionTarget) {
                this.travellersDescriptionTarget.classList.add('hidden');
                this.travellersStatusTarget.innerText = '';
                this.travellersTooltipTarget.innerText = '';
            }
            return;
        }

        // Update dates
        if (this.hasMinPriceTarget)
            this.minPriceTarget.classList.add('minprice--small');

        // Update status
        const activeStatus = document.querySelector(
            `.travel-tour-dates__status[data-date="${selectedDate}"]`,
        );

        const tooltipContent = activeStatus.dataset.tooltip;
        const statusLabel = activeStatus.dataset.status;

        if (this.hasTravellersDescriptionTarget) {
            this.travellersDescriptionTarget.classList.remove('hidden');
            this.travellersStatusTarget.innerText = statusLabel;
            this.travellersTooltipTarget.innerText = tooltipContent;
        }

        // fetch selected date element
        const activePrice = document.querySelector(
            `.travel-tour-dates__price[data-date="${selectedDate}"]`,
        );
        // retrieve all prices
        const formattedPrice = activePrice.dataset.formattedprice;
        const formattedRebatedPrice = activePrice.dataset.formattedrebatedprice;
        const rebatePercentageContent = activePrice.dataset.rebatepercentage;
        this.fromLabelTarget.classList.add('hidden');

        const price = activePrice.dataset.price;
        const rebatedPrice = activePrice.dataset.rebatedprice;
        let fidelityPoints = null;

        // display discounts
        if (formattedRebatedPrice) {
            if (this.hasMinPriceTarget) {
                this.minPriceTarget.classList.remove('hidden');
                this.minPriceTarget.innerHTML = formattedPrice;
            }
            if (this.hasRebatePercentageTarget) {
                this.rebatePercentageTarget.classList.remove('hidden');
                this.rebatePercentageTarget.innerHTML = rebatePercentageContent;
            }
            this.actualPriceTarget.innerHTML = formattedRebatedPrice;
            // update fidelity points
            fidelityPoints = Math.floor(Number(rebatedPrice));
        } else {
            // default case, normal price
            if (this.hasMinPriceTarget) {
                this.minPriceTarget.classList.add('hidden');
            }
            if (this.hasRebatePercentageTarget) {
                this.rebatePercentageTarget.classList.add('hidden');
            }
            this.actualPriceTarget.innerHTML = formattedPrice;
            // update fidelity points
            fidelityPoints = Math.floor(Number(price));
        }
        // update fidelity points labels
        if (this.hasSelectedDateFidelityPointsTarget) {
            this.selectedDateFidelityPointsTargets.forEach((element) => {
                element.innerHTML = fidelityPoints
                    .toLocaleString()
                    .replace(',', ' ');
            });
        }
        if (fidelityPoints >= 9000) {
            this.fidelityPointsAbove9000WrapperTargets.forEach((el) =>
                el.classList.remove('hidden'),
            );
            this.fidelityPointsWrapperTargets.forEach((el) =>
                el.classList.add('hidden'),
            );
        } else {
            this.fidelityPointsAbove9000WrapperTargets.forEach((el) =>
                el.classList.add('hidden'),
            );
            this.fidelityPointsWrapperTargets.forEach((el) =>
                el.classList.remove('hidden'),
            );
        }

        this.fidelityPointsAmountTargets.forEach(
            (el) =>
                (el.innerHTML =
                    // how much gift card of 40euros
                    Math.floor(fidelityPoints / 12000) * 40 +
                    // and how much gift card of 20euros
                    Math.floor((fidelityPoints % 12000) / 9000) * 20),
        );

        this.nextDatesErrorTarget.classList.add('hidden');

        // Update max travellers
        var nbAdults = document.querySelector('input[name=nbAdults]');
        var nbChildren = document.querySelector('input[name=nbChildren]');
        var remainingStock = document.querySelector(
            'select[name=startDate] option[value="' + selectedDate + '"]',
        ).dataset.remainingStock;
        if (remainingStock !== null) {
            nbAdults.setAttribute('max', remainingStock);
            nbAdults.setAttribute('maxStock', remainingStock);
            // trigger change-max event using native javascript event
            nbAdults.dispatchEvent(
                new CustomEvent('change-max', { detail: remainingStock }),
            );
        }
        // change date: reset input state
        if (nbAdults) {
            nbAdults.value = nbAdults.getAttribute('min');
            nbAdults.dispatchEvent(new Event('input', { bubbles: true }));
            nbAdults.dispatchEvent(new Event('reset'));
        }
        if (nbChildren) {
            nbChildren.value = '0';
            nbChildren.dispatchEvent(new Event('input', { bubbles: true }));
            nbChildren.dispatchEvent(new Event('reset'));
        }
    }
}
