import { Controller } from '@hotwired/stimulus';
import Cookies from 'js-cookie';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['form', 'success'];
    static values = {};
    static outlets = ['modal'];

    connect() {
        window._uxa = window._uxa || [];
        const inDevisFunnel =
            window.location.pathname.includes('demander-un-devis');
        const inBookingFunnel =
            window.location.pathname.includes('reservation');

        // Open popup after 30 seconds
        setTimeout(() => {
            const isAlreadyOpened = Cookies.get('newsletter_opened');

            if (!isAlreadyOpened && !inDevisFunnel && !inBookingFunnel) {
                Cookies.set('newsletter_opened', true, { expires: 7 });
                window._uxa.push([
                    'trackPageview',
                    window.location.pathname +
                        '?cs-popin-inscription-newsletter',
                ]);
                this.openModal();
            }
        }, 30000);
    }

    openModal() {
        if (this.modalOutlet) {
            this.modalOutlet.open();
        }
    }

    close() {
        if (this.modalOutlet) {
            this.modalOutlet.close();
        }
        const success = document.querySelector('.success');

        if (!success) {
            window._uxa.push(['trackPageview', window.location.pathname]);
        }
    }

    async submit(event) {
        event.preventDefault();
        const form = this.formTarget;
        form.querySelector('button').setAttribute('disabled', 'disabled');

        const formData = new FormData(form);

        try {
            const response = await fetch(form.getAttribute('action'), {
                method: form.getAttribute('method'),
                body: formData,
            });

            const data = await response.json();

            if (response.status !== 201) {
                form.querySelectorAll('.error').forEach((element) => {
                    element.remove();
                });

                form.querySelector('button').removeAttribute('disabled');
                if (data.msg) {
                    const errorMessage = document.createElement('strong');
                    errorMessage.innerText = data.msg;
                    errorMessage.classList.add(
                        'error',
                        'text-status-negative',
                        'font-xs',
                        'mt-xs',
                        'italic',
                    );
                    form.append(errorMessage);
                }
            } else {
                const successMessage = document.createElement('strong');
                successMessage.classList.add(
                    'success',
                    'flex',
                    'justify-center',
                    'text-content-brand',
                );
                successMessage.innerText = data.msg;
                form.replaceWith(successMessage);

                setTimeout(() => {
                    this.close();
                }, 5000);
            }
        } catch (e) {
            const errorMessage = document.createElement('strong');
            errorMessage.innerText = 'Une erreur est survenue';
            errorMessage.classList.add(
                'error',
                'text-status-negative',
                'font-xs',
                'mt-xs',
                'italic',
            );
            form.append(errorMessage);
        }
    }
}
