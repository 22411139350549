import './js/sentry';
import * as Turbo from '@hotwired/turbo';
import { registerSvelteControllerComponents } from '@symfony/ux-svelte';
import 'core-js/actual/structured-clone';
import('flatpickr/dist/themes/light.css');

Turbo.session.drive = false;

import './css/main.scss';
import '@splidejs/splide/css';
// import './js/consent-payment';
// import 'intl-tel-input/build/css/intlTelInput.css';

// start the Stimulus application
import { startStimulusApp } from '@symfony/stimulus-bridge';

// Registers Stimulus controllers from controllers.json and in the controllers/ directory
export const app = startStimulusApp();

// common
import AccordionController from './controllers/accordion_controller.js';
import ModalController from './controllers/modal_controller.js';
import TextareaController from './controllers/textarea_controller.js';
// saga
import AccountBookingsController from './controllers/account_bookings_controller.js';
import AlertAvailabilitiesBoxController from './controllers/alertAvailabilities_box_controller.js';
import AutosubmitFormController from './controllers/autosubmit_form_controller.js';
import BannerCarouselController from './controllers/banner_carousel_controller.js';
import BookingHelpCenterController from './controllers/booking_help_center_controller.js';
import CarouselController from './controllers/carousel_controller.js';
import CarouselMosaicController from './controllers/carousel_mosaic_controller.js';
import DatepickerController from './controllers/datepicker_controller.js';
import DropdownController from './controllers/dropdown_controller.js';
import FaqController from './controllers/saga/faq_controller.js';
import FeedbackBoxController from './controllers/feedback_box_controller.js';
import FetchInsuranceController from './controllers/fetch_insurance_controller.js';
import FetchReviewsController from './controllers/fetch_reviews_controller.js';
import FormSpinnerController from './controllers/form_spinner_controller.js';
import GiftcardController from './controllers/saga/giftcard_controller.js';
import HelpCenterController from './controllers/help_center_controller.js';
import InputCounterController from './controllers/input_counter_controller.js';
import InputNumberController from './controllers/input_number_controller.js';
import LandingShowroomCarouselController from './controllers/landing_showroom_carousel_controller.js';
import LightboxModalController from './controllers/lightbox_modal_controller.js';
import MenuDesktopController from './controllers/menu_desktop_controller.js';
import MenuMobileController from './controllers/menu_mobile_controller.js';
import NewsletterController from './controllers/newsletter_controller.js';
import ObfuscatorController from './controllers/obfuscator_controller.js';
import PaxSelectorController from './controllers/pax_selector_controller.js';
import PdpCarouselController from './controllers/pdp_carousel_controller.js';
import PdpMosaicCarouselController from './controllers/pdp_mosaic_carousel_controller.js';
import PopoverController from './controllers/popover_controller.js';
import ProductTrackingController from './controllers/product_tracking_controller';
import RadioInputController from './controllers/radio_input_controller.js';
import SeeMoreController from './controllers/see_more_controller.js';
import SelectMultipleController from './controllers/select_multiple_controller.js';
import ShowmoreController from './controllers/showmore_controller.js';
import TourNavController from './controllers/tour_nav_controller.js';
import TravelTourController from './controllers/travel_tour_controller.js';
import SearchResultSortController from './controllers/saga/search_result_sort_controller';
import SurveyReviewInputController from './controllers/saga/survey_review_input_controller';

// Register each controller
app.register('accordion', AccordionController);
app.register('account-bookings', AccountBookingsController);
app.register('alertAvailabilities-box', AlertAvailabilitiesBoxController);
app.register('autosubmit-form', AutosubmitFormController);
app.register('banner-carousel', BannerCarouselController);
app.register('booking-help-center', BookingHelpCenterController);
app.register('carousel', CarouselController);
app.register('carousel-mosaic', CarouselMosaicController);
app.register('datepicker', DatepickerController);
app.register('dropdown', DropdownController);
app.register('saga--faq', FaqController);
app.register('feedback-box', FeedbackBoxController);
app.register('fetch-insurance', FetchInsuranceController);
app.register('fetch-reviews', FetchReviewsController);
app.register('form-spinner', FormSpinnerController);
app.register('saga--giftcard', GiftcardController);
app.register('help-center', HelpCenterController);
app.register('input-counter', InputCounterController);
app.register('input-number', InputNumberController);
app.register('landing-showroom-carousel', LandingShowroomCarouselController);
app.register('lightbox-modal', LightboxModalController);
app.register('menu-desktop', MenuDesktopController);
app.register('menu-mobile', MenuMobileController);
app.register('modal', ModalController);
app.register('newsletter', NewsletterController);
app.register('obfuscator', ObfuscatorController);
app.register('pax-selector', PaxSelectorController);
app.register('pdp-carousel', PdpCarouselController);
app.register('pdp-mosaic-carousel', PdpMosaicCarouselController);
app.register('popover', PopoverController);
app.register('product-tracking', ProductTrackingController);
app.register('radio-input', RadioInputController);
app.register('see-more', SeeMoreController);
app.register('select-multiple', SelectMultipleController);
app.register('showmore', ShowmoreController);
app.register('textarea', TextareaController);
app.register('tour-nav', TourNavController);
app.register('travel-tour', TravelTourController);
app.register('search-result-sort', SearchResultSortController);
app.register('survey-review-input', SurveyReviewInputController);

registerSvelteControllerComponents(
    require.context('./svelte/saga/controllers', true, /\.svelte$/),
);
