import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['content', 'toggle'];

    showmore(event) {
        const customClasses = event?.params?.classes ?? null;

        if (this.hasContentTarget) {
            // only one dom block to display
            if (this.contentTargets.length === 1) {
                this.contentTarget.classList.remove('hidden');
                this.contentTarget.classList.add(customClasses);
            } else {
                // multiple e.g. a list
                this.contentTargets.forEach((content) => {
                    content.classList.remove('hidden');
                    content.classList.add(customClasses);
                });
            }
        }

        this.toggleTarget.remove();
    }
}
