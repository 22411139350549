import { Controller } from '@hotwired/stimulus';
import Splide from '@splidejs/splide';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static values = {
        carouselId: String,
    };

    splide;

    connect() {
        this.splide = new Splide(`#${this.carouselIdValue}`, {
            perPage: 1,
            perMove: 1,
            rewind: true,
            rewindByDrag: true,
            pagination: true,
            fixedHeight: 250,
            arrows: false,
            classes: {
                page: 'splide__pagination__page bg-content-quiet',
            },
        }).mount();
    }

    next() {
        this.splide.go('>');
    }

    prev() {
        this.splide.go('<');
    }
}
