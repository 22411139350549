import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = [
        'button',
        'nbAdults',
        'nbChildren',
        'childrenDetails',
        'childrenAgeInput',
        'childrenAgeForm',
    ];

    // Define the oldValue to handle +/- inputs
    nbChildrenTargetConnected(element) {
        element.oldValue = parseInt(element.value || 0);
    }

    refreshButtonText() {
        this.buttonTarget.innerHTML = `${this.nbAdultsTarget.value} adulte(s) - ${this.nbChildrenTarget.value} enfant(s)`;
    }

    onNbAdultsChange() {
        this.refreshButtonText();
        if (this.nbAdultsTarget.getAttribute('maxstock')) {
            this.onPaxChange();
        }
    }

    onNbChildrenChange(event) {
        const oldNbChildren = this.nbChildrenTarget.oldValue;
        const childrenValue = parseInt(event.target.value);
        const nbChildren = isNaN(childrenValue) ? 0 : childrenValue;

        this.refreshButtonText();

        const hasIncreasedChildren = nbChildren > oldNbChildren;
        if (hasIncreasedChildren) {
            const nbChildrenToAdd = nbChildren - oldNbChildren;
            for (let i = 1; i <= nbChildrenToAdd; i++) {
                this.addChildrenAgeInput();
            }
        }

        const hasDecreasedChildren = nbChildren < oldNbChildren;
        if (hasDecreasedChildren) {
            const nbChildrenToRemove = oldNbChildren - nbChildren;
            for (let i = 1; i <= nbChildrenToRemove; i++) {
                this.removeChildrenAgeInput();
            }
        }

        if (nbChildren === 0) {
            // hide children details if no children
            this.childrenDetailsTarget.classList.add('is-hidden');
        } else {
            this.childrenDetailsTarget.classList.remove('is-hidden');
        }

        this.nbChildrenTarget.oldValue = nbChildren;
        if (this.nbAdultsTarget.getAttribute('maxstock')) {
            this.onPaxChange();
        }
    }

    onPaxChange = () => {
        let newMaxAdults =
            this.nbAdultsTarget.getAttribute('maxstock') -
            this.nbChildrenTarget.value;
        this.nbAdultsTarget.setAttribute('max', newMaxAdults);
        if (this.nbAdultsTarget.value > newMaxAdults) {
            this.nbAdultsTarget.value(newMaxAdults);
        }
        // custom event sent for input-number controller
        this.nbAdultsTarget.dispatchEvent(new CustomEvent('update'));

        let newMaxChildren =
            this.nbAdultsTarget.getAttribute('maxstock') -
            this.nbAdultsTarget.value;
        this.nbChildrenTarget.setAttribute('max', newMaxChildren);
        if (this.nbChildrenTarget.value > newMaxChildren) {
            this.nbChildrenTarget.value(newMaxChildren);
        }
        // custom event sent for input-number controller
        this.nbChildrenTarget.dispatchEvent(new CustomEvent('update'));
    };

    addChildrenAgeInput() {
        // Retrieve the template, duplicate it and append it inside the DOM
        const template = document.getElementById('childrenAgeInputTemplate');
        this.childrenAgeFormTarget.appendChild(
            document.importNode(template.content, true),
        );

        // Update last input label
        const lastChildrenAgeInput =
            this.childrenAgeInputTargets[
                this.childrenAgeInputTargets.length - 1
            ];
        lastChildrenAgeInput.getElementsByClassName(
            'pax-selector__placeholder',
        )[0].innerHTML = 'Âge enfant ' + this.childrenAgeInputTargets.length;
    }

    removeChildrenAgeInput() {
        if (this.hasChildrenAgeInputTarget) {
            const lastChildrenAgeInput =
                this.childrenAgeInputTargets[
                    this.childrenAgeInputTargets.length - 1
                ];
            lastChildrenAgeInput.remove();
        }
    }
}
