import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['counter'];

    update(event) {
        const input = event.target;
        const length = input.value.length;
        const maxLength = input.maxLength;

        this.counterTarget.innerText = `${length}/${maxLength}`;
    }
}
