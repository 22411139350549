import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: "lazy" */
export default class SurveyReviewInputController extends Controller {
    static classes = ['selected', 'default'];

    click(event) {
        event.stopPropagation();
        // reset all labels to default
        const target = event.currentTarget;
        const targetedInput = target.querySelector('input');
        const clickedValue = targetedInput?.value;
        const labels = this.element.querySelectorAll('label');

        // reset all
        labels.forEach((label) => {
            label.classList.remove('fill-content-brand');
            label.classList.add('fill-white');
        });

        // on add filled color to the clicked label and its previous siblings
        labels.forEach((label) => {
            const labelInput = label.querySelector('input');
            if (
                labelInput &&
                Number(labelInput.value) <= Number(clickedValue)
            ) {
                label.classList.add('fill-content-brand');
                label.classList.remove('fill-white');
            }
        });
    }
}
