import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['section', 'link'];

    connect() {
        // Activate the first section
        if (this.sectionTargets.length > 0) {
            const firstSection = this.sectionTargets[0];
            firstSection.classList.add('is-active');
            firstSection.classList.remove('hidden');
            firstSection.classList.add('flex');

            // Dispatch content-shown event for the first section
            this.dispatchContentShownEvent(firstSection);
        }
        // Activate the first link
        if (this.linkTargets.length > 0) {
            const firstLink = this.linkTargets[0];
            firstLink.classList.add('is-active');
        }
    }

    toggle(event) {
        event.preventDefault();
        // Remove 'is-active' from all links
        const parentUl = event.target.parentNode.parentNode;
        for (let child of parentUl.children) {
            child.classList.remove('is-active');
        }
        // Add 'is-active' to the clicked link's parent
        event.target.parentNode.classList.add('is-active');

        // Show the corresponding section and hide the others
        this.sectionTargets.forEach((target) => {
            target.classList.remove('flex');
            target.classList.add('hidden');
            if (target.id === event.params.id) {
                target.classList.add('flex');
                target.classList.remove('hidden');

                // Dispatch a custom event when content is shown
                this.dispatchContentShownEvent(target);
            }
        });
    }

    dispatchContentShownEvent(element) {
        // Dispatch a custom event that other controllers can listen for
        const event = new CustomEvent('content-shown', {
            bubbles: true,
            detail: { container: element },
        });
        element.dispatchEvent(event);
    }
}
