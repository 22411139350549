import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = [
        'alertAvailabilitiesBox',
        'form',
        'success',
        'error',
        'content',
        'input',
        'date',
        'flexibility',
        'submit',
        'loader',
    ];

    connect() {
        this.resetHandler = this.reset.bind(this);
        window.addEventListener('modal-close', this.resetHandler);
    }

    disconnect() {
        window.removeEventListener('modal-close', this.resetHandler);
    }

    submitalertAvailabilities(event) {
        event.preventDefault();

        this.submitTarget.classList.add('hidden');
        this.loaderTarget.classList.remove('hidden');
        fetch(event.target.action, {
            method: event.target.method,
            body: new FormData(event.target),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return Promise.reject(response);
            })
            .catch((error) => {
                this.clear();
                this.contentTarget.classList.add('hidden');
                this.errorTarget.classList.remove('hidden');
                return Promise.reject(error);
            })
            .then((data) => {
                this.clear();
                this.contentTarget.classList.add('hidden');
                this.successTarget.classList.remove('hidden');
            });
    }

    clear() {
        document
            .querySelectorAll('.form__message')
            .forEach((message) => message.classList.add('hidden'));
    }

    reset() {
        if (
            this.hasContentTarget &&
            this.contentTarget.classList.contains('hidden')
        ) {
            this.contentTarget.classList.remove('hidden');
        }

        if (
            this.hasSuccessTarget &&
            !this.successTarget.classList.contains('hidden')
        ) {
            if (this.hasFormTarget) this.formTarget.reset();
            this.successTarget.classList.add('hidden');
        }

        if (
            this.hasSubmitTarget &&
            this.submitTarget.classList.contains('hidden')
        ) {
            this.submitTarget.classList.remove('hidden');
        }

        if (
            this.hasLoaderTarget &&
            !this.loaderTarget.classList.contains('hidden')
        ) {
            this.loaderTarget.classList.add('hidden');
        }
    }
}
