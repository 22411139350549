import { Controller } from '@hotwired/stimulus';
import Splide from '@splidejs/splide';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static values = {
        carouselId: String, // mandatory, allow multiple instances of carousel in one page
        config: Object,
    };
    static splide;

    initialConfig = {
        focus: 'center',
        breakpoints: {
            1300: {
                start: 0,
                perMove: 1,
                padding: '20px',
            },
        },
        autoWidth: true,
        autoHeight: true,
        gap: '16px',
        padding: '0px',
        arrows: false,
        pagination: false,
        flickPower: 100,
        rewind: true,
        rewindSpeed: 1000,
        rewindByDrag: true,
    };

    connect() {
        if (this.isInViewport()) {
            this.initializeCarousel();
        } else {
            this.observer = new IntersectionObserver(
                (entries) => {
                    if (entries[0].isIntersecting) {
                        this.initializeCarousel();
                        this.observer.disconnect();
                    }
                },
                { rootMargin: '120px' },
            );
            this.observer.observe(this.element);
        }
    }

    initializeCarousel() {
        console.log('carousel initialized');
        this.splide = new Splide(`#${this.carouselIdValue}`, {
            ...this.initialConfig,
            ...this.configValue,
        }).mount();
    }

    isInViewport() {
        const rect = this.element.getBoundingClientRect();
        return (
            rect.top <=
                (window.innerHeight || document.documentElement.clientHeight) &&
            rect.bottom >= 0
        );
    }

    disconnect() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    next = () => {
        if (this.splide) this.splide.go('>');
    };

    prev = () => {
        if (this.splide) this.splide.go('<');
    };
}
