import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class SearchResultSortController extends Controller {
    connect() {
        this.element.addEventListener('change', () =>
            this.element.form.submit(),
        );
    }
}
