const lineClampClasses = {
    1: 'line-clamp-1',
    2: 'line-clamp-2',
    3: 'line-clamp-3',
    4: 'line-clamp-4',
    5: 'line-clamp-5',
    8: 'line-clamp-[8]',
};

import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['text', 'button'];
    static values = {
        lines: { type: Number, default: 3 },
    };

    connect() {
        this.textTarget.classList.add(lineClampClasses[this.linesValue]);

        this.buttonTarget.innerText = 'Voir plus';

        this.checkOverflow();

        // Listen for the custom event 'content-shown' to check for overflow because of previous controller might have hidden the content like tour-nav
        document.addEventListener(
            'content-shown',
            this.handleContentShown.bind(this),
        );
    }

    disconnect() {
        // Clean up event listener when controller is disconnected
        document.removeEventListener(
            'content-shown',
            this.handleContentShown.bind(this),
        );
    }

    handleContentShown(event) {
        if (
            event.detail.container.contains(this.element) ||
            this.element.contains(event.detail.container)
        ) {
            setTimeout(() => this.checkOverflow(), 10);
        }
    }

    checkOverflow() {
        if (this.textTarget.scrollHeight > this.textTarget.clientHeight) {
            this.buttonTarget.classList.remove('hidden');
        } else {
            this.buttonTarget.classList.add('hidden');
        }
    }

    toggle() {
        const isClamped = this.textTarget.classList.contains(
            lineClampClasses[this.linesValue],
        );

        if (isClamped) {
            this.textTarget.classList.remove(lineClampClasses[this.linesValue]);
            this.buttonTarget.innerText = 'Voir moins';
        } else {
            this.textTarget.classList.add(lineClampClasses[this.linesValue]);
            this.buttonTarget.innerText = 'Voir plus';
        }
    }
}
