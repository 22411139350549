import { Controller } from '@hotwired/stimulus';
import Splide from '@splidejs/splide';

/*
 * Specific carousel that
 */
/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static splide;
    static values = {
        config: Object,
    };

    initialConfig = {
        focus: 'center',
        mediaQuery: 'min',
        breakpoints: {
            1300: {
                destroy: true,
                padding: '20px',
            },
        },
        autoWidth: true,
        autoHeight: true,
        gap: '16px',
        padding: '0px',
        arrows: false,
        pagination: false,
        flickPower: 100,
        rewind: true,
        rewindSpeed: 1000,
        rewindByDrag: true,
    };

    connect() {
        if (this.isInViewport()) {
            this.initializeCarousel();
        } else {
            this.observer = new IntersectionObserver(
                (entries) => {
                    if (entries[0].isIntersecting) {
                        this.initializeCarousel();
                        this.observer.disconnect();
                    }
                },
                { rootMargin: '120px' },
            );
            this.observer.observe(this.element);
        }
    }

    initializeCarousel() {
        console.log('carousel initialized');
        this.splide = new Splide(`#carousel-mosaic`, {
            ...this.initialConfig,
            ...this.configValue,
        }).mount();
    }

    isInViewport() {
        const rect = this.element.getBoundingClientRect();
        return (
            rect.top <=
                (window.innerHeight || document.documentElement.clientHeight) &&
            rect.bottom >= 0
        );
    }

    disconnect() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }
}
